import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useUserStore } from '@/store/base'
import { usePermissionStore } from '@/store/permission'
import { usePageStore } from '@/store/page'
import pinia from '@/store'
import { RouteRecordRaw } from 'vue-router'
import { useWorkOrderWebsocket, useDCWebsocket } from '@/hooks'
import { whitePathList } from '@/router'

router.beforeEach(async (to, from, next) => {
  // @/store 导出pinia，store模块调用useSote(pinia)传入pinia，可在组件外使用store
  const userStore = useUserStore(pinia)
  const pageStore = usePageStore(pinia)
  const permissionStore = usePermissionStore(pinia)
  userStore.refreshStore() //刷新页面同步缓存里的pageStore到pinia
  pageStore.refreshStore() // 刷新页面同步缓存里的userInfo到pinia
  useDCWebsocket() //注册全局 /dc/下的 websocket

  console.log('beforeEach', to, from)
  NProgress.start()
  const title: any = to.meta?.title || process.env.VUE_APP_TITLE
  document.title = title

  if (userStore?.userInfo?.tokenInfo?.access_token) {
    if (!permissionStore.routers.length) {
      const accessRoutes = await permissionStore.generateRoutes(userStore.userInfo.rolesList || ['admin'])
      accessRoutes.forEach(route => {
        router.addRoute(route) //addRoute(route) 递归添加route下的子孙路由，router.getRoutes() 可查询到添加的所有子孙路由。removeRoute删除路由时同时删除子孙路由。
      })
      next({ ...to, replace: true }) //解决addRoute 后立即路由跳转不生效问题
    } else {
      // 未匹配到任何路由，跳转404
      if (to.matched.length === 0) {
        from.name ? next({ name: from.name }) : next('/404')
      } else {
        // 已登录+登录未失效，控制不能跳转进入登录页面。登录失效应进入登录页面
        if (to.path == '/login') {
          next('/')
        } else {
          next()
        }
      }
      // 登录每个月执行一次，不能放在登录页面，创建工单websocket监听放在导航守卫
      useWorkOrderWebsocket(userStore?.userInfo?.tokenInfo?.user_name?.userCode)
    }
  } else {
    if (whitePathList.indexOf(to.path) != -1) {
      next()
    } else {
      pageStore.setUrl(to.path)
      next('/login')
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
