<!-- 组件设计：CommonSearch查询处理handleQuery放在父组件页面；
  CommonPagination数据pageData:{pageNum,pageSize,totalCount}在页面内使用hooks统一生成，可供CommonSearch、CommonPagination修改使用；
  CommonTable只做展示，不操作其他组件数据 -->
<template>
  <div class="common-table-component">
    <!-- :max-height 不能随ref值修改而重新渲染，使用:style？？？ -->
    <el-table
      :data="tableData.tableList"
      :stripe="props.stripe"
      :max-height="maxHeight"
      :header-cell-style="props.headerCellStyle ? { background: '#EBF3FE', color: '#333' } : undefined"
      :show-summary="!!props.summaryMethod"
      :summary-method="!!props.summaryMethod ? props.summaryMethod : undefined"
      :highlight-current-row="props.hightlightCurrentRow"
      @selection-change="handleSelectionChange"
      @current-change="handleCurrentChange"
      @row-dblclick="handleDblClick"
      @row-click="handleRowClick"
      @row-contextmenu="handleRowContextmenu"
      @cell-click="handleCellClick"
      :row-class-name="props.tableRowClassName"
      :header-row-class-name="props.tableHeaderRowClassName"
      :span-method="props.spanMethod"
      :style="{ 'max-height': maxHeight + 'px' }"
      v-loading="props.loading"
      :empty-text="props.emptyText"
      :border="props.border"
    >
      <el-table-column v-if="!!props.isShowIndex" type="index" width="55"></el-table-column>
      <el-table-column v-if="!!props.isShowSelection" type="selection" width="55"></el-table-column>
      <el-table-column
        v-for="(item, index) in columnList"
        :key="index"
        :prop="item.prop"
        :label="item.label"
        :min-width="item.minWidth || 110"
        :width="item.width || undefined"
        :show-overflow-tooltip="item.showOverflowTooltip != null ? item.showOverflowTooltip : true"
      >
        <template v-if="item.headerSlotName" #header="scope">
          {{ item.label }} <slot v-if="item.headerSlotName" :name="item.headerSlotName" v-bind="scope"></slot>
        </template>

        <!-- 任意页面调用父组件<CommonTable> <template #name="scope"> </CommonTable>，其内多个 <el-table-column> 的命名插槽占位借助 当前子组件内 el-table 的各个<el-table-column>的默认插槽实现。
        通过 <template  #default="scope"> 把当前子组件作用域传递给  <slot v-bind="scope"> 命名插槽，再传回给父组件内对应的<template #name="scope">，
          最终把子组件作用域变量传递给父组件  -->
        <template v-if="item.slotName" #default="scope">
          <slot v-if="item.slotName" :name="item.slotName" v-bind="scope"></slot>
        </template>

        <!-- 二级表头，每个表头下的多个 el-table-column -->
        <template v-if="item.children?.length">
          <el-table-column
            v-for="(subItem, subIndex) in item.children"
            :key="subIndex"
            :prop="subItem.prop"
            :label="subItem.label"
            :min-width="subItem.minWidth || 110"
            :width="subItem.width || undefined"
            :show-overflow-tooltip="subItem.showOverflowTooltip != null ? subItem.showOverflowTooltip : true"
          >
            <template v-if="subItem.headerSlotName" #header="scope">
              {{ subItem.label }}
              <slot v-if="subItem.headerSlotName" :name="subItem.headerSlotName" v-bind="scope"></slot>
            </template>
            <template v-if="subItem.slotName" #default="scope">
              <slot v-if="subItem.slotName" :name="subItem.slotName" v-bind="scope"></slot>
            </template>
          </el-table-column>
        </template>
      </el-table-column>
      <el-table-column
        v-if="props.isShowOption"
        :min-width="optionColumnWidth"
        :label="props.optionColumnName || '操作'"
        fixed="right"
      >
        <template #default="scope">
          <slot name="optionColumn" v-bind="scope"></slot>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref, toRef, toRefs, inject, watch, Ref, nextTick } from 'vue'
import { useTableMaxHeight } from '@/hooks/index'
import { debounce } from 'lodash-es'
// import { TableData, columnItem } from '@/types/CommonTable'

interface TableData {
  tableList: any[]
  multipleSelection?: any[]
}
interface ColumnItemBase {
  prop: string
  label: string
  width?: number
  minWidth?: number
  showOverflowTooltip?: boolean
  slotName?: string
  headerSlotName?: string // 表格项 表格头 插槽名，slotName+'Header'
}
interface ColumnItem extends ColumnItemBase {
  children?: ColumnItemBase[]
}

let props = withDefaults(
  defineProps<{
    tableData: TableData
    columnList: ColumnItem[]
    isShowIndex?: boolean //索引列
    isShowSelection?: boolean //多选框列
    isShowOption?: boolean //操作列
    summaryMethod?: (data: { columns: any[]; data: any[] }) => string[]
    loading?: boolean
    emptyText?: string
    optionColumnName?: string
    hightlightCurrentRow?: boolean //单选
    border?: boolean
    headerCellStyle?: boolean //标题行默认背景色
    stripe?: boolean
    maxHeight?: number
    tableRowClassName?: (data: { row: any; rowIndex: number }) => string | string //行的 className 的回调方法
    tableHeaderRowClassName?: (data: { row: any; rowIndex: number }) => string | string //表头行的 className 的回调方法
    spanMethod?: any
    optionColumnWidth?: number
  }>(),
  {
    tableData: () => ({
      tableList: [],
      multipleSelection: [],
    }),
    isShowIndex: false,
    isShowSelection: false,
    isShowOption: false,
    loading: false,
    hightlightCurrentRow: false,
    border: false,
    headerCellStyle: true,
    stripe: true,
    optionColumnWidth: 130,
  },
)

const emit = defineEmits(['dblClick', 'currentChange', 'selectionChange', 'rowClick', 'rowContextmenu', 'cellClick'])

let { tableData, columnList } = toRefs(props)

// 多选
const handleSelectionChange = (val: any[]) => {
  tableData.value.multipleSelection = val
  emit('selectionChange')
}
// 单选
const handleCurrentChange = (currentRow: any, oldCurrentRow: any) => {
  tableData.value.multipleSelection = [currentRow]
  emit('currentChange')
}
// 单击行
const handleRowClick = (row, column, event) => {
  if (props.hightlightCurrentRow) {
    tableData.value.multipleSelection = [row]
  }
  emit('rowClick', row)
}
// 双击行
const handleDblClick = (row: any) => {
  emit('dblClick', row)
}
// 右击行
const handleRowContextmenu = (row, column, event) => {
  event.preventDefault()
  emit('rowContextmenu', { row, column, event })
}
// 单击单元格
const handleCellClick = (row, column, cell, event) => {
  emit('cellClick', { row, column, cell, event })
}

let maxHeight = ref(props.maxHeight ?? 600)

// 多层嵌套的 CommonTable 组件，在适当的时机重新获取 maxHeight，上传组件 provide 注入响应式变量
let tableIsShow: Ref<boolean> = inject('tableIsShow', ref(false))
watch(
  () => tableIsShow?.value,
  debounce(newValue => {
    if (newValue === true) {
      nextTick(() => {
        if (!props.maxHeight) {
          maxHeight.value = useTableMaxHeight()
        }
      })
    }
  }, 50),
)

onMounted(() => {
  if (!props.maxHeight) {
    maxHeight.value = useTableMaxHeight() //组件 mounted 但可能并未显示，基于 document.querySelector('.el-table') 计算的高度可能出错
    console.log('mounted--', maxHeight.value)
    window.addEventListener(
      'resize',
      debounce(() => {
        maxHeight.value = useTableMaxHeight()
      }, 150),
    )
  }
})
</script>

<style scoped lang="less">
:deep .el-table__row.warning-row {
  background-color: rgb(253, 246, 236);
}
</style>
